import React from 'react'
import styled from 'styled-components'
import aboutImg from '../assets/images/community.jpg'
import { KServices, Kontact } from '../components/komman'
import { KScrollTop } from '../components/kglobal'
const SupportPage = () => {
    return (
        <main>
            <div className='title'></div>
            <Wrapper className='page section section-center'>
                <img
                    src={aboutImg}
                    alt='nice desk'
                />
                <article>
                    <div className='title'>
                        <h2>Our Support</h2>
                        <div className='underline'></div>
                        <p>
                            Core essential values are the set of guiding
                            principles and fundamental beliefs that help a group
                            of people function together as a team and work
                            toward a common goal. Core values can play important
                            role in solving environmental and social problems.
                            Therefore, actions in this regard are also
                            everyone’s responsibility, and we will all benefit
                            from the results. This website is sponsored and
                            contributed by KALPSOFTECH which is driven by
                            experts who has around decades worth of rich
                            experience across diversified web and mobile
                            application technology stack.
                        </p>
                    </div>
                </article>
            </Wrapper>
            <KServices />
            <Kontact />
            <KScrollTop />
        </main>
    )
}
const Wrapper = styled.section`
    // border-style: solid;
    // border-color: red;
    padding: 2rem 0;
    display: grid;
    gap: 3rem;
    // margin-top: 1rem;

    img {
        max-width: 100%;
        display: block;
        border-radius: var(--radius);
        // border-style: solid;
        height: 100%;
        // object-fit: cover;
        object-fit: contain;
    }
    p {
        line-height: 2;
        max-width: 45em;
        margin: 1rem auto;

        color: var(--clr-grey-5);
    }
    .title {
        text-align: left;
    }
    .underline {
        margin-left: 0;
    }
    @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }
`
export default SupportPage
