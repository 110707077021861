import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const KScrollTop = () => {
    const [backToTopbutton, setBackToTopButton] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    })

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <Wrapper>
            {backToTopbutton && (
                <button
                    className='backToTop'
                    style={{
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                        height: '50px',
                        width: '50px',
                        fontSize: '50px',
                    }}
                    onClick={scrollUp}
                >
                    ^
                </button>
            )}
        </Wrapper>
    )
}
const Wrapper = styled.div`
.backToTop {
    position: 'fixed',
    bottom: '50px',
    right: '50px',
    height: '50px',
    width: '50px',
    fontSize: '50px',
}
`
export default KScrollTop
