import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const KReactVideo = ({ url, name, desc }) => {
    return (
        <Wrapper>
            <div className='player-wrapper'>
                <ReactPlayer
                    className='react-player'
                    url={url}
                    width='100%'
                    height='100%'
                    controls={true}
                    modestbranding={true}
                    muted={true}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 0 },
                        },
                    }}
                />
            </div>
            <footer>
                <h5>{name}</h5>
                <p>{desc}</p>
            </footer>
        </Wrapper>
    )
}
const Wrapper = styled.section`
    .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
    footer {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    footer h5,
    footer p {
        margin-bottom: 0;
        font-weight: 400;
    }
    footer p {
        color: var(--clr-primary-5);
        letter-spacing: var(--spacing);
    }
`
export default KReactVideo
