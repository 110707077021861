import React, { useState } from 'react'
import styled from 'styled-components'
import { KScrollTop } from '../components/kglobal'
import { festivalDetails } from '../utils/festival_constants'

const FestivalPage = () => {
    const [value, setValue] = useState(0)
    const { url } = festivalDetails[value]
    return (
        <Wrapper>
            <section className='page'>
                <div className='tabs-center'>
                    <aside className='btn-container'>
                        {festivalDetails.map((item, index) => {
                            return (
                                <button
                                    key={item.id}
                                    onClick={() => setValue(index)}
                                    className={`tab-btn ${
                                        index === value && 'active-btn'
                                    }`}
                                >
                                    {item.title}
                                </button>
                            )
                        })}
                    </aside>
                    <section className='photo'>
                        <img
                            src={url}
                            alt={'festival'}
                        />
                    </section>
                </div>
            </section>
            <KScrollTop />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .tabs-center {
        width: 80vw;
        margin: 2rem auto;
        max-width: var(--max-width);
    }

    .btn-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .photo {
        position: relative;
        overflow: hidden;
    }
    .photo > img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    .tab-btn {
        background: transparent;
        border-color: transparent;
        text-transform: capitalize;
        font-size: 1.25rem;
        letter-spacing: var(--spacing);
        margin: 0 0.5rem;
        transition: var(--transition);
        cursor: pointer;
        padding: 0.25rem 0;
        line-height: 1;
        outline-color: var(--clr-primary-10);
    }

    .tab-btn:hover {
        color: var(--clr-primary-5);
        box-shadow: 0 2px var(--clr-primary-5);
    }
    .active-btn {
        color: var(--clr-primary-5);
        box-shadow: 0 2px var(--clr-primary-5);
    }
    @media screen and (min-width: 992px) {
        .tabs-center {
            width: 90vw;
            display: grid;
            grid-template-columns: 200px 1fr;
            column-gap: 4rem;
        }
        .btn-container {
            flex-direction: column;
            justify-content: flex-start;
        }
        .tab-btn {
            margin-bottom: 1rem;
        }
        .active-btn {
            box-shadow: -2px 0 var(--clr-primary-5);
        }
        .tab-btn:hover {
            box-shadow: -2px 0 var(--clr-primary-5);
        }
    }
`
export default FestivalPage
