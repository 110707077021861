import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { KTopbar, KSidebar, KFooter } from './components/kglobal'
import { Support, Festival, Gallery, Home, Error } from './pages'

const App = () => {
    return (
        <Router>
            <KTopbar />
            <KSidebar />
            <Routes>
                <Route
                    path='/'
                    element={<Home />}
                />
                <Route
                    path='/gallery'
                    element={<Gallery />}
                />
                <Route
                    path='/festival'
                    element={<Festival />}
                />
                <Route
                    path='/support'
                    element={<Support />}
                />
                <Route
                    path='*'
                    element={<Error />}
                />
            </Routes>
            <KFooter />
        </Router>
    )
}

export default App
