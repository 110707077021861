import React from 'react'
import {
    GiBanjo,
    GiCompass,
    GiDiamondHard,
    GiStabbedNote,
    GiPhotoCamera,
    GiAura,
} from 'react-icons/gi'
export const navLinks = [
    {
        id: 1,
        text: 'home',
        url: '/',
    },
    {
        id: 2,
        text: 'festival',
        url: '/festival',
    },
    {
        id: 3,
        text: 'gallery',
        url: '/gallery',
    },
    {
        id: 4,
        text: 'support',
        url: '/support',
    },
]

export const aboutServices = [
    {
        id: 1,
        icon: <GiCompass />,
        title: 'mission',
        text: 'Provide a comprehensive, boutique IT service to Small and Medium sized businesses (SMB’s), sole traders and start-ups, provisioning and supporting their core IT business services.',
    },
    {
        id: 2,
        icon: <GiDiamondHard />,
        title: 'vision',
        text: 'Elevate mobile and web application user interfaces, engineering, data and infra operations to the cloud as IaaS, PaaS, SaaS business models',
    },
    {
        id: 3,
        icon: <GiStabbedNote />,
        title: 'history',
        text: 'The K Software is driven by Experts who has around two decade’s worth of rich experience across diversified web and mobile application technology stack. In 2022 established as start-up company',
    },
]

export const featuredServices = [
    {
        id: 1,
        icon: <GiBanjo />,
        title: 'Festival',
        url: '/festival',
    },
    {
        id: 2,
        icon: <GiPhotoCamera />,
        title: 'Gallery',
        url: '/gallery',
    },
    {
        id: 3,
        icon: <GiAura />,
        title: 'Support',
        url: '/support',
    },
]
