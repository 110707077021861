import React from 'react'
import { videoList } from '../../../utils/galleryData'
import ReactVideo from './KReactVideo'
import KVideoGridView from './KVideoGridView'

const KVideoList = () => {
    if (videoList.length < 1) {
        return (
            <h5 style={{ textTransform: 'none' }}>Sorry, No Videos found...</h5>
        )
    }
    return <KVideoGridView videos={videoList}>List of Videos</KVideoGridView>
}

export default KVideoList
