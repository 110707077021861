import React from 'react'
import styled from 'styled-components'
import KPhoto from './KPhoto'

const KPhotoGridView = ({ photos }) => {
    return (
        <Wrapper>
            <div className='photos'>
                <div className='photos-container'>
                    {photos.map((photo) => {
                        return (
                            <KPhoto
                                key={photo.id}
                                {...photo}
                            />
                        )
                    })}
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    .photos {
        padding: 5rem 0;
    }

    .photos-container {
        width: 90vw;
        max-width: var(--max-width);
        margin: 0 auto;
        display: grid;
        gap: 2rem;
    }
    @media screen and (min-width: 576px) {
        .photos-container {
            grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
        }
    }
`

export default KPhotoGridView
