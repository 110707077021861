import React from 'react'
import { photoList } from '../../../utils/galleryData'
import KPhotoGridView from './KPhotoGridView'

const KPhotoList = () => {
    if (photoList.length < 1) {
        return (
            <h5 style={{ textTransform: 'none' }}>Sorry, No Photos found...</h5>
        )
    }
    return <KPhotoGridView photos={photoList}>photo list</KPhotoGridView>
}

export default KPhotoList
