import React from 'react'
import styled from 'styled-components'

const KPhoto = ({ url, name, desc }) => {
    return (
        <Wrapper>
            <div className='photo'>
                <img
                    src={url}
                    alt={desc}
                />
            </div>
            <footer>
                <h5>{name}</h5>
                <p>{desc}</p>
            </footer>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    .photo {
        height: 17.5rem;
        position: relative;
        overflow: hidden;
        border-radius: var(--radius);
        cursor: pointer;
    }
    .photo > img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    .photo:hover img {
        opacity: 1;
    }

    footer {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    footer h5,
    footer p {
        margin-bottom: 0;
        font-weight: 400;
    }
    footer p {
        color: var(--clr-primary-5);
        letter-spacing: var(--spacing);
    }
`
export default KPhoto
