import React from 'react'
import styled from 'styled-components'

const Kontact = () => {
    return (
        <Wrapper>
            <div className='section-center'>
                <h4>To share your feedback or support or contribute...</h4>
                <p>Please write us at Email id: kdevalayam@gmail.com</p>
            </div>
        </Wrapper>
    )
}
const Wrapper = styled.section`
    padding: 4rem 0;
    margin-top: 5rem;
    text-align: center;

    h3 {
        text-transform: none;
    }
    p {
        line-height: 2;
        // max-width: 25em;
        color: var(--clr-grey-5);
    }
`

export default Kontact
