import React from 'react'
import styled from 'styled-components'
import KReactVideo from './KReactVideo'

const KVideoGridView = ({ videos }) => {
    return (
        <Wrapper>
            <div className='videos'>
                <div className='videos-container'>
                    {videos.map((video) => {
                        return (
                            <KReactVideo
                                key={video.id}
                                {...video}
                            />
                        )
                    })}
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    .videos {
        padding: 5rem 0;
    }
    .videos-container {
        width: 90vw;
        max-width: var(--max-width);
        margin: 0 auto;
        display: grid;
        gap: 2rem;
    }

    @media screen and (min-width: 576px) {
        .videos-container {
            grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
        }
    }
`

export default KVideoGridView
