export const festivalDetails = [
    {
        id: 1,
        order: 1,
        title: 'Welcome',
        date: '',
        url: '/assets/images/festival/f1.jpg',
    },
    {
        id: 2,
        order: 2,
        title: 'Introduction',
        date: '',
        url: '/assets/images/festival/f2.jpg',
    },
    {
        id: 3,
        order: 3,
        title: '16 Dec 2022',
        date: ' Day 1 ',
        url: '/assets/images/festival/f3.jpg',
    },
    {
        id: 4,
        order: 4,
        title: '17 Dec 2022',
        date: ' Day 2 ',
        url: '/assets/images/festival/f4.jpg',
    },
    {
        id: 5,
        order: 5,
        title: '18 Dec 2022',
        date: ' Day 3',
        url: '/assets/images/festival/f5.jpg',
    },
]
