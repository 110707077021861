import React from 'react'
import { Kontact, KHomeHero, FeaturedServices } from '../components/komman'

const HomePage = () => {
    return (
        <main>
            <KHomeHero />
            <FeaturedServices />
            <Kontact />
        </main>
    )
}

export default HomePage
