import React, { useState } from 'react'
import styled from 'styled-components'
import { KPhotoList, KVideoList } from '../components/kgallery'
import { KScrollTop } from '../components/kglobal'
const tabList = [
    {
        id: '1',
        category: 'photos',
    },
    {
        id: '2',
        category: 'videos',
    },
]
const GalleryPage = () => {
    const [value, setValue] = useState(0)
    const { category } = tabList[value]

    return (
        <main>
            <Wrapper className='page'>
                <div className='section-center'>
                    <div className='top-margine'>
                        <div className='btn-container'>
                            {tabList.map((item, index) => {
                                return (
                                    <button
                                        key={item.id}
                                        onClick={() => setValue(index)}
                                        className={`tab-btn ${
                                            index === value && 'active-btn'
                                        }`}
                                    >
                                        <div className='title'>
                                            <h2>{item.category}</h2>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>

                        <div>{category === 'photos' && <KPhotoList />}</div>
                        <div>{category === 'videos' && <KVideoList />}</div>
                    </div>
                </div>
                <KScrollTop />
            </Wrapper>
        </main>
    )
}

const Wrapper = styled.div`
    .top-margine {
        margin: 2rem auto;
    }
    .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .tab-btn {
        background: transparent;
        border-color: transparent;
        text-transform: capitalize;
        font-size: 1.25rem;
        letter-spacing: var(--spacing);
        margin: 0 0.5rem;
        margin-right: 3rem;
        margin-left: 3rem;
        transition: var(--transition);
        cursor: pointer;
        padding: 0.25rem 0;
        line-height: 1;
        outline-color: var(--clr-primary-10);
    }

    .active-btn {
        color: var(--clr-primary-5);
        box-shadow: 0 2px var(--clr-primary-5);
    }
`

export default GalleryPage
