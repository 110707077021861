import React from 'react'
import { FaBars } from 'react-icons/fa'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/home//logo.jpg'
import { navLinks } from '../../utils/constants'
import { useGlobalContext } from '../../context/app_context'

const KTopbar = () => {
    const { openSidebar } = useGlobalContext()
    return (
        <NavContainer>
            <div className='nav-center'>
                <div className='nav-header'>
                    <Link to='/'>
                        <img
                            src={logo}
                            alt='Store'
                        />
                    </Link>
                    <button
                        type='button'
                        className='nav-toggle'
                        onClick={openSidebar}
                    >
                        <FaBars />
                    </button>
                </div>
                <ul className='nav-links'>
                    {navLinks.map((link) => {
                        const { id, text, url } = link
                        return (
                            <li key={id}>
                                <Link to={url}>{text}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </NavContainer>
    )
}

const NavContainer = styled.nav`
    // border-style: solid;
    // border-color: red;
    // position: fixed;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-center {
        width: 90vw;
        margin: 0 auto;
        max-width: var(--max-width);
    }
    .nav-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            width: 175px;
            margin-left: -15px;
        }
    }
    .nav-toggle {
        background: transparent;
        border: transparent;
        color: var(--clr-primary-5);
        cursor: pointer;
        svg {
            font-size: 2rem;
        }
    }
    .nav-links {
        display: none;
    }

    @media (min-width: 992px) {
        .nav-toggle {
            display: none;
        }
        .nav-center {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
        }
        .nav-links {
            display: flex;
            justify-content: center;
            li {
                margin: 0 0.5rem;
            }
            a {
                color: var(--clr-grey-3);
                font-size: 1rem;
                text-transform: capitalize;
                letter-spacing: var(--spacing);
                padding: 0.5rem;
                &:hover {
                    border-bottom: 2px solid var(--clr-primary-7);
                }
            }
        }
    }
`
export default KTopbar
