export const photoList = [
    {
        id: 1,
        name: 'Festival',
        url: '/assets/images/gallery/p1.jpg',
        desc: 'Dec 2021',
    },
    {
        id: 2,
        name: 'Festival',
        url: '/assets/images/gallery/p2.jpg',
        desc: 'Dec 2021',
    },
    {
        id: 3,
        name: 'Festival',
        url: '/assets/images/gallery/p3.jpg',
        desc: 'Dec 2021',
    },
    {
        id: 4,
        name: 'Festival',
        url: '/assets/images/gallery/p4.jpg',
        desc: 'Dec 2021',
    },
    {
        id: 5,
        name: 'Festival',
        url: '/assets/images/gallery/p5.jpg',
        desc: 'Dec 2021',
    },
    {
        id: 6,
        name: 'Festival',
        url: '/assets/images/gallery/p6.jpg',
        desc: 'March 2021',
    },
]

export const videoList = [
    {
        id: 1,
        name: 'Festival',
        url: 'https://youtu.be/nGzsZXjZsqs',
        desc: 'Dec 2021',
    },
    {
        id: 2,
        name: 'Festival',
        url: 'https://youtu.be/51H5nlZJiHc',
        desc: 'Dec 2021',
    },
    {
        id: 3,
        name: 'Festival',
        url: 'https://youtu.be/_tLt8CJbDWY',
        desc: 'Dec 2021',
    },
    {
        id: 4,
        name: 'Festival',
        url: 'https://youtu.be/OZ35nsP57aw',
        desc: 'Dec 2021',
    },
]
